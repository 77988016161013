import { themeIcons } from "config/theme";
import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Modal, ModalBody, ModalHeader, Spinner } from "reactstrap";
import VIcon from "components/custom/Icon";
import { useDispatch, useSelector } from "react-redux";
import PaginationBar from "../../../components/custom/PaginationBar";
import { ApiKey, ApiUrl,AlertTypes } from "util/Constant";
import ApiEngine from "util/ApiEngine";
import { showResponseMessage,setSpinWheelListModal,setSpinWheelReportModal,showMessage,setBusy,setIdle } from "redux/AppAction";
import { find } from "lodash";
import { numberWithCurrencyFormat,stringIsNullOrEmpty } from "../../../util/Util";
import moment from "moment";
import SpinWheelClaimRecordPopUp from "./SpinWheelClaimRecordPopUp";



const SpinWheelListPopUpModal = (props) => {
  var { t } = useTranslation();
  var _dispatch = useDispatch();

  const openSpinWheelAsModal = false;
  const [paginationPageNum, setPaginationPageNum] = useState(10);
	const [isResetCurrentPage, setIsResetCurrentPage] = useState(false);
  const walletsDetails = useSelector((state) => state.appState.walletsDetails);
  var { heartBeat } = useSelector((state) => state.authState);
  const [utcTimeForTooltip, setUtcTimeForTooltip] = useState();


  const spinWheelListModal = useSelector((state) => state.appState.spinWheelListModal);
  const [isLoadingList, setIsLoadingList] = useState(false);
  const [spinWheelList, setSpinWheelList] = useState([]);
  const [shouldShowSpinWheel, setShouldShowSpinWheel] = useState(false);
  const companyIcons = useSelector((state) => state.appState.companyIcons);
	const [coinIcon, setCoinIcon] = useState();
  const [emptyPromoIcon, setEmptyPromoIcon] = useState();
  const [iframeSrc, setIframeSrc] = useState('');
  const [isLoadingBtn, setIsLoadingBtn] = useState(false);

  let firstLoad = false;
  const intervalRef = useRef(null); // Ref to track the interval
	const hasShownError = useRef(false); // Ref to track if the error message has been shown

  useEffect(() => {
		const utcTimezone = moment.utc().format("h:mm:ss A");
		setUtcTimeForTooltip(utcTimezone);
		firstLoad = true;
	}, []);

 
  useEffect(() => {
		if (shouldShowSpinWheel && spinWheelListModal) {
      getMySpins();

			// Create interval for spin wheel heartbeat
			intervalRef.current = setInterval(() => {
				// Call the spin wheel heartbeat function
				getMySpins();
			}, 10000);

			return () => {
				// Cleanup the interval on component unmount
				clearInterval(intervalRef.current);
			};
		}
	}, [shouldShowSpinWheel,spinWheelListModal]);

  useEffect(() => {

		if (heartBeat) {
			//setIsLogin(true);
			init();
		}
	}, [heartBeat]);


  useEffect(() => {
		if (companyIcons.length > 0) {
			getCompanyIcon();
		}
	}, [companyIcons]);

  async function getCompanyIcon() {
		const coinIcon = find(companyIcons, { iconCategory: "coin_icon" });
		if (coinIcon) {
			setCoinIcon(coinIcon.iconImage);
		}

    const emptyPromoIcon = find(companyIcons, { iconCategory: "empty_promotion" });
		if (emptyPromoIcon) {
			setEmptyPromoIcon(emptyPromoIcon.iconImage);
		}

	}


  async function init() {
		// Get spinwheel settings
		let responseJson = await ApiEngine.get(
		ApiUrl._API_GET_SPINWHEEL_SETTINGS
		);
	
		if(responseJson[ApiKey._API_SUCCESS_KEY]){
			var spinWheelEnabled = false;
			var showSpinWheelForGuest = false;
		
			if(responseJson[ApiKey._API_DATA_KEY].spinWheelEnabled == "1"){
				spinWheelEnabled = true;
			}
		
			if(responseJson[ApiKey._API_DATA_KEY].showSpinWheelForGuest == "1"){
				showSpinWheelForGuest = true;
			}
		
			if(heartBeat && spinWheelEnabled) {
				setShouldShowSpinWheel(true);
			} else if(!heartBeat && spinWheelEnabled && showSpinWheelForGuest) {
				setShouldShowSpinWheel(true);
			}
		}
	}

  async function getMySpins(page) {
    try{
		if(firstLoad) {
			_dispatch(setBusy());
			setIsLoadingList(true);
		}

    page = page ? page : 1;
    const itemsPerPage = 10; 
    var start = (page - 1) * itemsPerPage;
    const end = start + itemsPerPage;

    var apiUrl=ApiUrl._API_GET_MEMBER_SPINS+
    "?start="+start+"&count="+itemsPerPage;

		let responseJson = await ApiEngine.get(apiUrl);

		if (responseJson[ApiKey._API_SUCCESS_KEY]) {

      var paginatedData = responseJson[ApiKey._API_DATA_KEY][ApiKey._API_DATA_KEY];
 
        for(var i=0;i<paginatedData.length;i++){
          paginatedData[i]["expiryDateTxt"]=calculateTimeLeft(paginatedData[i]["expiryDate"]);
        };

			setSpinWheelList(paginatedData);
      var totalCount=responseJson[ApiKey._API_DATA_KEY].totalCount;
      updatePageinationPageNum(Math.ceil(totalCount  / itemsPerPage));
		} else {
			_dispatch(
				showResponseMessage(false, "ERROR_LOADING_MY_SPINS")
			);

			// If an error occurs, handle the interval clearing logic
			if (!hasShownError.current) {
				hasShownError.current = true; // Mark the error as shown
				clearInterval(intervalRef.current); // Clear the interval
				_dispatch(showResponseMessage(false, "ERROR_LOADING_MY_SPINS"));
			}
		}

		if(firstLoad) {
			_dispatch(setIdle());
			setIsLoadingList(false);
			firstLoad = false;
		}
    }catch (err) {
      _dispatch(showResponseMessage(false, err));
    }
  return;
	}

  async function initSpin(memberSpinWheelId) {
		{openSpinWheelAsModal && (
			// Clear iframe
			setIframeSrc('')
		)}

		_dispatch(setBusy());
		setIsLoadingBtn(memberSpinWheelId);
	
		let responseJson = await ApiEngine.post(
		  ApiUrl._API_IMREWARD_SPIN,
		  {
			"memberSpinWheelId": memberSpinWheelId,
		  }
		);

		_dispatch(setIdle());
		setIsLoadingBtn(false);
	
		if(responseJson[ApiKey._API_SUCCESS_KEY]){
		  if(!stringIsNullOrEmpty(responseJson[ApiKey._API_DATA_KEY].url)){
			{openSpinWheelAsModal ? (
				setIframeSrc(responseJson[ApiKey._API_DATA_KEY].url)
			) : (
				// Open new window
				window.open(responseJson[ApiKey._API_DATA_KEY].url, '_blank')
			)}
		  }
		} else {
		  _dispatch(
			showMessage({
			  type: AlertTypes._ERROR,
			  content: t(responseJson[ApiKey._API_MESSAGE_KEY]),
			})
		  );
		}
	}


function updatePageinationPageNum(pageValue) {
  setPaginationPageNum(prevValue => pageValue);
}

  const calculateTimeLeft = (expiryDate) => {

    if (!expiryDate) {
      return "-";
    }

    const now = new Date();
    const expiry = new Date(expiryDate);

    if (isNaN(expiry.getTime())) {
      return "-";
    }
  
    const diff = expiry - now;
  
    if (diff <= 0) {
      return (`0${t("DAY")}`).toLowerCase();
    }
  
    const days = Math.floor(diff / (1000 * 60 * 60 * 24));
    const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
  
    const parts = [];
  
    if (days > 0) {
      const dayLabel = days === 1 ? t("DAY"): t("DAYS");
      parts.push(`${days}${dayLabel}`);
    }
  
    if (hours > 0) {
      const hourLabel = hours === 1 ? t("HR") : t("HRS");
      parts.push(`${hours}${hourLabel}`);
    }
  
    if (minutes > 0) {
      const minuteLabel = minutes === 1 ? t("MIN_"): t("MINS");
      parts.push(`${minutes}${minuteLabel}`);
    }
  
    return parts.join(" ").toLowerCase();
  };
  return (
    <div>

      <Modal
        id="t3-custom-modal"
        className="modal-custom-fullscreen"
        contentClassName={"t3-custom-modal t3-custom-modal-with-back-btn min-height-600px"}
        fade={false}
        isOpen={spinWheelListModal}
        toggle={() => {
          _dispatch(setSpinWheelListModal(false))
        }}
      >
        <ModalHeader>
          <div className="first">
            <div>{t("SPIN_WHEEL_TOKENS")}</div>
          </div>
          <div className="second">
            <div
              className="t3-close-custom-modal"
              onClick={() => _dispatch(setSpinWheelListModal(false))}
            >
              <VIcon icon={themeIcons.close} className="vicon" />
            </div>
          </div>
        </ModalHeader>
        <ModalBody>
          <div className="t3-custom-modal-container">
            <div className="custom-body edit-profile" >


            <div className="spin-wallet-container">
             <div className="wallet-row">
             <div className="wallet-info" style={{alignSelf:"start"}}>
      <img
        src={coinIcon}
        style={{ width: "50px", height: "50px" }}
        alt="img-wallet-coin"
      />
      <div>
        <div> {`${t("WALLET_BALANCE")}:`}</div>
        <div
          style={{
            fontWeight: "Bold",
            color: "var(--theme-color-bg-btn-submit)",
          }}
        >
          {numberWithCurrencyFormat(
            walletsDetails.currentWalletBalance,
            2,
            true
           )}
           </div>
         </div>
         </div>
        <button
         className="t3-custom-light-btn record-button"
           onClick={() => {
            _dispatch(setSpinWheelReportModal(true));
            _dispatch(setSpinWheelListModal(false));          
           }}
          >
           <img
           src={require("../../../assets/img/new_icons/record.svg")}
           alt="img-wallet"
            />
            <div>{t("RECORD")}</div>
             </button>
             </div>
            </div>
                <div className="t3-alink" style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "0.5rem",
                      padding:"20px 0px",
                      textDecoration:"none"
                     }}>
								<i className="fa fa-clock"></i>
                <span>{t("SPINS_WHEEL_REMINDER")}</span>
                </div>
                

                {isLoadingList && 
										<div className="t3-spinwheel-token-item loading" style={{borderBottom:"none"}}>
											<div className="d-flex align-items-center justify-content-center">
												<Spinner animation="border" size="lg" style={{ color: "var(--theme-color-bg-btn-submit)" }} />
											</div>
										</div>
									}
           {!isLoadingList && spinWheelList && spinWheelList.length > 0 && (
             <> <div className="spin-wheel-card-container">
               {spinWheelList.map((item, index) => (
               <div className="spin-wheel-card" key={index}>
                <div style={{fontWeight:"Bold",color:"var(--theme-color-bg-btn-submit)"}}>
                  {`${t("SPIN_WHEEL")} #${item.id}`}
                </div>
                <div>
                  {`${t("CAMPAIGN")}: ${item.campaignName}`}
                </div>
                <div className="time-container">
								<i className="fa fa-clock" ></i>
                <span className="t3-alink" style={{textDecoration:"none"}}>
                  {item.expiryDateTxt}
                </span>
                </div>

                {item.completedAt == null ? (
														isLoadingBtn == item.id ? 
                            <div className="t3-spinwheel-token-spin" style={{opacity: "0.5",width:"100%"}}>
                            <Spinner animation="border" size="sm" />
                           </div>
														:
															<button 
																className="t3-spinwheel-token-spin"
                                style={{width:"100%"}}
																onClick={() => { 
																	initSpin(item.id)
																	// setIsModalOpen(!isModalOpen);
																}}
															>
																<div>{t("SPIN_NOW")}</div>
															</button>
                              
													) : (
														<div className="t3-spinwheel-token-spin completed" style={{width:"100%"}}>
									         {t("COMPLETED")}
								           </div>
													)}

                </div>
                ))}
                </div>
              
        {paginationPageNum > 1 && (
				<PaginationBar
					totalPages={paginationPageNum}
					retrieveDataRecords={getMySpins}
					isResetCurrentPage={isResetCurrentPage}
					setIsChangeTab={setIsResetCurrentPage}
				/>
			  )}
        </>
           )}


                {!isLoadingList && spinWheelList && spinWheelList.length == 0 && (
										<div className="t3-spinwheel-token-item empty" style={{border:"none"}}>
											<div className="d-flex flex-column align-items-center justify-content-center">
                        <img
                        src={emptyPromoIcon}
                        alt="img-promo-empty"
                        style={{ height: "230px",width:"300px" }}
                        />
													<div style={{ color: "var(--theme-color-text-placeholder)",textAlign:"center" }}>{t("NO_SPIN_WHEEL_TOKENS")}</div>				
											</div>
										</div>
									)}

            </div>
          </div>
        </ModalBody>
      </Modal>
      <SpinWheelClaimRecordPopUp/>
    </div>

  );
};

export default SpinWheelListPopUpModal;
