import React, { useState, useRef } from "react";
import ReactPlayer from "react-player";
import { themeIcons } from "config/theme";
import VIcon from "components/custom/Icon";

const MusicPlayer = () => {
    const [playing, setPlaying] = useState(false);
    const [played, setPlayed] = useState(0);
    const [volume, setVolume] = useState(1);
    const [volumeMemory, setVolumeMemory] = useState(1);
    const [muted, setMuted] = useState(false);
    const [loop, setLoop] = useState(false);
    const [seeking, setSeeking] = useState(false);

    const [currentTrack, setCurrentTrack] = useState(0);
    const tracks = [
        "https://soundcloud.com/chenyuan/da-khuc-chau-kiet-luan-jay-chou", // URL for Track 1
        "https://pksoftcdn.azureedge.net/media/music/Afternoon before falling asleep.mp3", // URL for Track 2
        "https://music.youtube.com/watch?v=ZcBMj7_Er80", // URL for Track 3
        "https://music.youtube.com/watch?v=xAR6N9N8e6U", // URL for Track 4
        "https://music.youtube.com/watch?v=oPVte6aMprI", // URL for Track 5
    ];

    const trackNames = ["soundcloud", "cdn", "youtube 1", "youtube 2", "youtube 3"];

    const handleNext = () => {
        setCurrentTrack((prevTrack) => (prevTrack + 1) % tracks.length); // Loop back to the first track
    };

    const handlePrevious = () => {
        setCurrentTrack(
            (prevTrack) => (prevTrack - 1 + tracks.length) % tracks.length
        ); // Loop back to the last track
    };

    var playerRef = useRef(null);

    const handlePlayPause = () => {
        setPlaying(!playing);
    };

    const handleStop = () => {
        setPlaying(false);
    };

    const handlePlay = () => {
        console.log("onPlay");
        setPlaying(true);
    };

    const handleVolumeChange = (e) => {
        setVolume(e.target.value);
        setVolumeMemory(e.target.value);
    };

    const handleSeekMouseDown = (e) => {
        setSeeking(true);
    };

    const handleSeekChange = (e) => {
        setPlayed(parseFloat(e.target.value));
    };

    const handleSeekMouseUp = (e) => {
        setSeeking(false);
        playerRef.current.seekTo(parseFloat(e.target.value));
    };

    const handleToggleMuted = () => {
        setMuted(!muted);
        if (muted === false) {
            setVolume(0);
            setVolumeMemory(volume);
        }
        else {
            setVolume(volumeMemory);
        }
    };

    const handleToggleLoop = () => {
        console.log("trigger");
        setLoop(!loop);
    };

    const handleProgress = (state) => {
        // We only want to update time slider if we are not currently seeking
        if (!seeking) {
            setPlayed(parseFloat(state.played));
        }
    };

    return (
        <div className="music-player">
            <div className="audio-player-container">
                <ReactPlayer
                    ref={playerRef}
                    url={tracks[currentTrack]} // Platform URL (YouTube, SoundCloud, etc.)
                    //url={"https://music.youtube.com/watch?v=xAR6N9N8e6U"}
                    playing={playing} // Auto-play the audio
                    controls={false}
                    volume={parseFloat(volume)}
                    width="0px" // Hide video
                    height="0px" // Hide video
                    onPlay={handlePlay}
                    muted={muted}
                    loop={loop}
                    onProgress={handleProgress}
                />
                <div style={{ justifyItems:"center" }}>
                    <h2 style={{ color: "white" }}>{trackNames[currentTrack]}</h2>
                </div>
                <div className="music-player-controls-container">
                    <div
                        className="control-button"
                        onClick={handlePrevious}
                    >
                        <VIcon
                            icon={themeIcons.previousBtn}
                            className="theme-icon-size-20"
                        />
                    </div>
                    <div
                        className="control-button"
                        onClick={handlePlayPause}
                    >
                        {playing ?
                            <VIcon
                                icon={themeIcons.pauseButton}
                                className="theme-icon-size-20"
                            /> :
                            <VIcon
                                icon={themeIcons.playButton}
                                className="theme-icon-size-20"
                            />
                        }
                    </div>
                    <div
                        className="control-button"
                        onClick={handleNext}
                    >
                        <VIcon
                            icon={themeIcons.nextBtn}
                            className="theme-icon-size-20"
                        />
                    </div>
                    <div
                        className="control-button"
                        onClick={handleToggleLoop}
                    >
                        <VIcon
                            icon={themeIcons.loop}
                            className={`theme-icon-size-20 ${loop ? "control-active" : ""}`}
                        />
                    </div>
                    <div className="volume-controls-container">
                        <div onClick={handleToggleMuted}>
                            {muted ? 
                                <VIcon
                                    icon={themeIcons.volumeMute}
                                    className="theme-icon-size-20"
                                /> :
                                <VIcon
                                    icon={themeIcons.volumeHigh}
                                    className="theme-icon-size-20"
                                />
                            }
                        </div>
                        <input
                            type="range"
                            className="custom-volume-range"
                            min={0}
                            max={1}
                            step="any"
                            value={volume}
                            onChange={handleVolumeChange}
                        />
                    </div>
                </div>
                <table style={{ marginTop: "10px" }}>
                    <tbody>
                        <tr>
                            <td>
                                <input
                                    type="range"
                                    min={0}
                                    max={0.999999}
                                    step="any"
                                    value={played}
                                    onMouseDown={handleSeekMouseDown}
                                    onChange={handleSeekChange}
                                    onMouseUp={handleSeekMouseUp}
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default MusicPlayer;
